// Secondary button
.p-button.p-button-secondary {
	color: #ffffff;
	background-color: $secondary;
	border: 1px solid $secondary;
}

.p-button.p-button-secondary:enabled:hover {
	color: #ffffff;
	background-color: shade($secondary, 10%);
	border-color: $secondary;
}

.p-button.p-button-secondary.p-button-outlined {
	color: $secondary;
	background-color: transparent;
	border: 1px solid $secondary;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover {
	color: #ffffff;
	background-color: $secondary;
	border-color: $secondary;
}

.p-button.p-button-secondary.p-button-text {
	color: $secondary;
	background-color: transparent;
	border: none;
	text-decoration: underline;
}

.p-button.p-button-secondary.p-button-text:enabled:hover {
	color: white;
	background: $secondary;
	text-decoration: underline;
}

// Button Link
.p-button-link > .p-button-label {
	color: #2d98d4;
}
