app-support {
	.support-main {
		padding: 0 4rem;
	}

	.video-thumbnail {
		border-radius: 8px;
		width: 100%;
	}

	.plyr__video-wrapper, .plyr {
		border-radius: 0.5rem;
	}

	.plyr--full-ui.plyr--video .plyr__control--overlaid {
		display: none;
	}

	.play-icon {
		position: absolute;
		left: 50%;
		top: 50%;
	}

	.video {
		position: relative;
	}

	.video > a {
		background-color: rgba(255, 255, 255, 0.25);
		height: 45px;
		width: 45px;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 11;
		padding-left: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.video.overlay::after {
		border-radius: 8px;
	}

	.overlay::after {
		position: absolute;
		left: 0;
		top: 0px;
		width: 100%;
		height: 100%;
		content: '';
		background: #002032;
		opacity: 0;
	}

	@include media-breakpoint-down(lg) {
		.support-main {
			padding: 0 1rem;
		}
	}

	.link {
		text-decoration: none;
		color: $secondary;
	}
}
