app-register-without-code-main {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.registration-steps {
		padding: 6rem 8rem;
		background: white;

		.p-steps {
			margin: 5rem 0rem;
			.p-steps-item {
				&::before {
					margin-top: 0rem !important;
				}

				.p-menuitem-link {
					cursor: pointer;
					.p-steps-number {
						border-color: #2d98d4;
						color: #2d98d4;
					}

					.p-steps-title {
						display: none;
					}
				}

				&.p-highlight {
					.p-menuitem-link {
						box-shadow: 0 0 0 8px #e4f2f9;
						border-radius: 50%;

						&:not(.p-disabled):focus {
							box-shadow: 0 0 0 8px #e4f2f9;
						}

						.p-steps-number {
							background: #2d98d4;
							color: white;
						}
					}
				}
			}
		}

		.active-step-1 {
			.p-steps-item:nth-child(1) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-2 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-3 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2),
			.p-steps-item:nth-child(3) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.review-details {
			.total-cost-container {
				max-width: 330px;
				width: 100%;

				.card {
					background: #fafbfc;
					border: 1px solid #e4e9f0;

					.card-body {
						padding: 1.5rem;
					}
				}

				.p-inputgroup {
					button {
						border-radius: 0 6px 6px 0;
					}
				}
			}
		}

		.stripe-checkout {
			max-width: 330px;
			width: 100%;

			.card {
				background: #fafbfc;
				border: 1px solid #e4e9f0;

				.card-body {
					padding: 1.5rem;
				}
			}
		}

		.partner-registration-code {
			max-width: 330px;
			width: 100%;
		}
	}

	@include media-breakpoint-down(xl) {
		.registration-steps {
			padding: 3rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.registration-steps {
			padding: 3rem 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.review-details {
			.total-cost-container {
				max-width: 100% !important;
			}
		}
	}
}
