app-certification-exam-result {
	.exam-result-container {
		background-color: #ffffff;
		padding: 2rem 8rem;

		.result-flex-container {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
			.deny-icon {
				color: #f44336;
				height: 16px;
				width: 16px;
				border-radius: 50%;
				background: #ffebee;
				box-shadow: 0px 0px 0px 5px #ffebee;
			}
			.passed-icon {
				color: #38cb89;
				height: 16px;
				width: 16px;
				border-radius: 50%;
				background: #e5f6ef;
				box-shadow: 0px 0px 0px 5px #e5f6ef;
			}
			.result-text {
				width: 75%;
			}
			.details-card {
				width: 30rem;
			}
		}
	}
	@include media-breakpoint-down(xl) {
		.exam-result-container {
			padding: 2rem;
		}
	}

	@include media-breakpoint-down(lg) {
		.exam-result-container {
			.result-flex-container {
				flex-direction: column;
				gap: 5rem;
				.result-text {
					width: 100%;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.exam-result-container {
			.result-flex-container {
				.details-container {
					width: 100%;
					.details-card {
						width: 100%;
					}
				}
			}
		}
	}
}
