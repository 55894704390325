app-import-external-certifications {
	.arrow {
		padding: 1rem;
		padding-left: 8rem;
		align-items: center;
	}

	.import-ext-cert-container {
		padding: 8rem;
		background-color: #ffffff;
		min-height: 100%;
	}

	.bottom-card {
		padding: 1rem;
		border: 1px solid #e9ecef;
		border-radius: 1rem;
		background-color: #fafbfc;
		max-width: 28rem;
	}

	.import-external-certs-tabmenu-items .p-tabmenu {
		.p-tabmenu-nav-content {
			padding: 0 0.5rem;
		}
		.p-tabmenu-nav {
			border-top: none;
			padding: 0;
		}

		.p-tabmenuitem:first-child {
			padding-left: 0;
		}
	}
	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	@media (max-width: 960px) {
		.import-ext-cert-container {
			padding: 5rem 1rem;
		}
	}
}
