app-partner-profile-billing {
	.edit-billing-info-form {
		grid-template-columns: repeat(auto-fit, minmax(350px, 2fr));
		gap: 1rem;
	}

	.p-dropdown {
		width: 100%;
	}

	#country .p-disabled,
	#country .p-component:disabled,
	#state .p-disabled,
	#state .p-component:disabled {
		opacity: 1;
		background-color: #fafbfc;
	}

	@media screen and (min-width: 1900px) {
		.edit-billing-info-form {
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
		}
	}
}
