app-exam-scores {
	.exam-score-dialog .p-dialog {
		width: 35rem;
	}

	.passed {
		color: #38cb89;
	}

	.failed {
		color: #ff5630;
	}
}
