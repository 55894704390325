.p-dialog.p-confirm-dialog {
	width: 498px;
	.p-confirm-dialog-message {
		margin-left: 0;
		text-align: center;
	}

	.p-dialog-header {
		flex-wrap: wrap;

		span {
			height: 55px;
			width: 55px;
			background: tint($secondary, 85%);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: $secondary;
			box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
		}
	}

	.p-dialog-content {
		justify-content: center;
	}

	.p-dialog-footer {
		display: flex;
		justify-content: space-between;

		.p-button {
			padding: 0.5rem 1.25rem;
		}

		.p-confirm-dialog-accept {
			margin-right: 0;
			background-color: $secondary;
			border-color: $secondary;
		}

		.p-icon-wrapper {
			display: none;
		}

		.p-confirm-dialog-accept:hover {
			background-color: #0b6191;
			border-color: #0b6191;
		}

		.p-confirm-dialog-reject {
			background-color: white;
			border-color: $secondary;
			color: $secondary;
		}

		.p-confirm-dialog-reject:hover {
			background-color: #0b6191;
			border-color: #0b6191;
			color: white;
		}

		button {
			width: 48%;

			.p-button-label {
				font-weight: 400;
			}
		}
	}
}
