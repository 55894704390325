.p-dialog {
	box-shadow: none;
	width: 862px;
	.p-dialog-header {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		color: $secondary;
		justify-content: center;

		.p-dialog-title {
			font-size: 1.5rem;
			font-weight: 400;
		}
	}

	.p-dialog-content {
		color: $sideBarText;

		form {
			label {
				font-weight: 400;
				font-size: 1rem;
				color: $primary;
			}

			p-inputnumber {
				display: block;

				.p-inputnumber {
					width: 100%;
				}
			}

			.p-calendar {
				width: 100%;
			}

			.p-dropdown {
				width: 100%;
			}

			input {
				width: 100%;
			}

			input:focus-visible {
				outline-color: tint-color($sideBarText, 50%);
			}

			::placeholder,
			::-webkit-input-placeholder {
				color: tint-color($sideBarText, 50%);
			}
		}
	}

	.p-dialog-footer {
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;

		button {
			margin: 0;
		}
	}
}
