@import '../../node_modules/bootstrap/scss/functions';
// GLOBAL SITEWIDE VARIABLES

// Overriding defaults
$primary: #002433;
$secondary: #2d98d4;
$error-color: #dc3545;
$warning: #c52024;

$sideBarText: #7a7a7a;

$font-family-base: 'SofiaPro';

@import '../../node_modules/bootstrap/scss/variables';

$font-size-m: 18px;
$font-size-sm: $font-size-base * 0.9375;
$font-size-xs: $font-size-base * 0.8125;

$navbar-dark-toggler-border-color: transparent;

@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

abp-breadcrumb {
	display: none;
}

abp-confirmation {
	.confirmation-backdrop {
		background-color: black;
		opacity: 50%;
	}
	.confirmation-dialog {
		background-color: white;
		border-radius: 1rem;
		border-width: 1px;
		border-color: transparent;
	}
}

.validation-error {
	color: $error-color;
	font-size: 0.875em;
	margin-top: 0.25rem;
	width: 100%;
	font-weight: bold;
}
