app-lead {
	.page-title {
		padding: 1.5rem 2rem;
		width: 100%;
		background-color: white;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.delete-export-btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.mobile-filters {
		display: none;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: block;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}

	@media screen and (max-width: 455px) {
		.delete-export-btns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 0.25rem;
			width: 100%;

			.p-button {
				width: 100%;
			}
		}

		.mobile-filters {
			width: 100%;
			margin-bottom: 0.25rem;

			.p-button {
				width: 100%;
			}
		}
	}
}
