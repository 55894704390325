app-selected-partner {
	.page-title {
		padding: 1.5rem 2rem;
		width: 100%;
		background-color: white;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.otherInformationMenuItems .p-tabmenu {
		margin-bottom: 1rem;

		.p-tabmenu-nav {
			background-color: transparent !important;
			padding: 0 !important;
			border: none !important;
			.p-menuitem-text {
				font-size: 14.5px !important;
			}
			.p-tabmenuitem {
				padding: 0 !important;
				background: transparent !important;
				.p-menuitem-link {
					color: tint-color($sideBarText, 5%);
					padding: 0.5rem 0 !important;
					margin: 0 1rem;
					border: none !important;
					background-color: transparent !important;
				}
			}
			.p-tabmenuitem.p-highlight .p-menuitem-link.p-menuitem-link-active {
				color: $secondary;
				background-color: #e4f2f9 !important;
				padding: 0 1rem !important;
				margin: 0;
				border-radius: 16px;
				font-weight: 500;
				position: relative;

				:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 0 !important;
				}
			}
			.p-tabmenuitem:hover,
			.p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
				background-color: #e4f2f9 !important;
				border-radius: 16px;
				color: $secondary;
			}
			.p-tabmenuitem .p-menuitem-link,
			.p-tabmenuitem.p-highlight .p-menuitem-link {
				background-color: white;
				font-weight: 400;
			}
		}
	}

	.registrationsTabMenuItems .p-tabmenu,
	.testingSitesTabMenuItems .p-tabmenu {
		.p-tabmenu-nav-content {
			padding: 0 0.5rem;
		}
		.p-tabmenu-nav {
			border-top: none;
			padding: 0;
		}

		.p-tabmenuitem:first-child {
			padding-left: 0;
		}
	}

	.registrationsTitle {
		padding: 0 0.5rem;
	}

	.p-dialog {
		width: 70rem;
	}

	.edit-partner-form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 1rem;
		column-gap: 2rem;
	}

	.edit-partner-logo-container {
		margin-bottom: 4rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	.partner-details {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1rem;
	}

	@media screen and (max-width: 1200px) {
		.partner-details {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media screen and (max-width: 700px) {
		.partner-details {
			grid-template-columns: repeat(1, 1fr);

			.primary-text,
			.gray-text {
				text-align: center;
			}
		}

		.edit-partner-form {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
