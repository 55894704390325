app-memo {
	.p-inputtext {
		background: rgba(250, 251, 252, 0.5);
		border: 1px solid rgba(128, 128, 128, 0.25);
		border-radius: 4px;
		resize: none;
	}

	.btn {
		color: #ffffff;
		background: #0d72aa;
		width: 10rem;
	}
}
