app-student-dashboard {
	.welcome-header {
		padding: 5rem;
		background-color: #e4f2f980;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.registration-code-card {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			background-color: #2d98d4;
			color: #ffffff;
			border-radius: 1rem;
			padding: 2.5rem;
			max-width: 500px;

			a:hover {
				font-weight: bold !important;
			}
		}
	}

	.registrations {
		padding: 5rem;
		background: white;

		.exam-registrations-container,
		.ce-course-registrations-container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
			gap: 2rem;

			.exam-registrations-card,
			.ce-course-registrations-card {
				border-radius: 2rem;
				border: 1px solid #e9ecef;
				padding: 1rem 2rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	.study-guide-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
		gap: 2rem;

		.study-guide-card {
			border-radius: 2rem;
			border: 1px solid #e9ecef;
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	}

	.links-container {
		padding: 5rem;

		.cert-links-container,
		.renewal-links-container {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
		}

		.cert-link-card,
		.renewal-link-card {
			border-radius: 2rem;
			border: 1px solid #e9ecef;
			padding: 1rem;
			text-align: center;
			background-color: #ffffff;
			width: 100%;
		}

		.renewal-link-card {
			padding: 2rem 1rem;
		}
	}

	.video-tutorials-section {
		padding: 7rem;

		.video-tutorials-container {
			display: flex;
			gap: 2rem;
			width: 100%;

			.video-card {
				width: 100%;
				border-radius: 2rem;
				border: 1px solid #e9ecef;
				padding: 1rem 1rem;
				padding-bottom: 4rem;
				max-height: 32rem;

				img {
					max-height: 18rem;
					min-height: 18rem;
					object-fit: cover;
				}
			}
		}

		.video-tutorials-header {
			padding-bottom: 1rem;
		}
	}

	img {
		border-radius: 1rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	@include media-breakpoint-down(xl) {
		.cert-links-container,
		.renewal-links-container {
			display: grid !important;
			grid-template-columns: repeat(2, 1fr);
		}

		.exam-registrations-card,
		.ce-course-registrations-card {
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center !important;
		}
		.exam-registrations-container,
		.ce-course-registrations-container,
		.study-guide-container {
			gap: 1rem !important;
		}
	}

	@include media-breakpoint-down(lg) {
		.welcome-header {
			padding: 1rem;
			flex-direction: column;
			align-items: center;
		}

		.registrations {
			padding: 2rem 1rem;
		}

		.links-container {
			padding: 2rem 1rem;
		}

		.video-tutorials-section {
			padding: 2rem 1rem;
		}

		.registration-code-card {
			margin-top: 1rem;
			padding: 1rem !important;
		}
	}

	@include media-breakpoint-down(md) {
		.cert-links-container,
		.renewal-links-container {
			grid-template-columns: 1fr;
		}

		.exam-registrations-container,
		.ce-course-registrations-container {
			grid-template-columns: 1fr !important;
		}

		.video-tutorials-container {
			flex-direction: column;
			align-items: center;

			.video-tutorials-header {
				text-align: center;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.cert-link-card,
		.renewal-link-card {
			max-width: 100% !important;
			width: 100% !important;
		}

		.video-card {
			width: 100% !important;
		}
	}
}
