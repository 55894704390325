app-browse-student-certifications {
	.registration-code-container {
		background-color: #2d98d4;
		color: #ffffff;
		border-radius: 2rem;
		flex-grow: 1;
		max-width: 50rem;
	}

	.left-header {
		flex-grow: 1;
	}
	.browse-certifications-container {
		padding: 8rem 10rem;
	}

	.header-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.arrow {
		padding: 1rem;
		padding-left: 10rem;
		display: flex;
		align-items: center;
	}

	.registration-code-input {
		margin-right: 2rem;
	}

	.certification-list-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		justify-content: center;
		row-gap: 3rem;
		column-gap: 2rem;
		margin-top: 5rem;
	}
	.certification-card {
		border-radius: 2rem;
		border: 1px solid #e9ecef;
		padding: 1rem;
		text-align: center;
	}

	@media (max-width: 1345px) {
		.browse-certifications-container {
			padding: 3rem;
		}
	}

	@include media-breakpoint-down(lg) {
		.header-section {
			flex-direction: column;
			align-items: flex-start;
		}
		.registration-code-container {
			margin-top: 2rem;
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		.registration-code-image {
			display: none;
		}
		.registration-code-input {
			margin-right: 0;
		}

		.registration-code-container {
			padding: 2rem;
		}
	}
}
