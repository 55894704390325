app-registration-change {
	.reason-header {
		color: #002433;
	}
	.reason-description {
		border: 1px solid $secondary;
		padding: 1rem;
		border-radius: 0.375rem;
	}
}
