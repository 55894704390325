app-course-quiz {
	.correct-icon {
		color: #38cb89;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 5px #e5f6ef;
	}

	.incorrect-icon {
		color: #f44336;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #ffebee;
		box-shadow: 0px 0px 0px 5px #ffebee;
	}

	.p-radiobutton-disabled {
		cursor: default;
	}

	.test-container {
		display: flex;
		gap: 4rem;
		.mobile-screen {
			display: none;
		}
	}

	.main-course {
		overflow-y: initial;
	}

	.quiz-question, .quiz-answer {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	@include media-breakpoint-down(md) {
		.test-container {
			flex-direction: column;
			.mobile-screen {
				display: block;
				margin-bottom: 3rem;
			}
		}
	}
}
