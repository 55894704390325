app-course-sidebar {
	.p-dialog {
		.lock-icon {
			margin-top: 1rem;
			height: 55px;
			width: 55px;
			background: #d5eaf6;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: #2d98d4;
			box-shadow: 0px 0px 0px 8px #eaf5fb;
		}
	}

	.ok-btn {
		width: 50%;
	}
}
