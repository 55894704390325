.p-tabview {
	.p-tabview-nav {
		li:not(:last-child) {
			margin-right: 1rem;

			.p-tabview-nav-link {
				font-weight: 400;
				padding-left: 0;
				padding-right: 0;
			}
		}

		li.p-highlight .p-tabview-nav-link {
			font-weight: 400;
			border: none;
			color: $secondary;
			position: relative;

			:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				background-color: $secondary;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}
	}

	.p-tabview-panels {
		padding-left: 0;
		padding-right: 0;
	}
}
