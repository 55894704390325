/* You can add global styles to this file, and also import other style files */
@import "scss/variables";
@import "scss/vendors";
@import "scss/site";
// :root .lpx-theme-dark {
//   --lpx-logo: url('/assets/images/logo/logo-light.png');
//   --lpx-logo-icon: url('/assets/images/logo/icon-light.png');
// }

// :root .lpx-theme-light {
//   --lpx-logo: url('/assets/images/logo/logo-dark.png');
//   --lpx-logo-icon: url('/assets/images/logo/icon-dark.png');
// }

html, body { 
  height:100%;
  overflow-x: hidden;
}

body {
  background-color: #fafbfc;
}

:host {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; 
}