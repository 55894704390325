app-points-of-contact {
	.point-of-contact-form,
	.designated-billing-administrator {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
		row-gap: 1rem;
		column-gap: 3rem;
	}

	.form-container {
		display: flex;
	}

	.form {
		width: 70rem;
	}

	.p-dropdown {
		width: 100%;
	}
}
