app-student-account {
	.p-menu {
		padding: 0.5rem;
		width: 20rem;
		border-radius: 1rem;
	}

	.p-dropdown {
		width: 100%;
	}

	.passed {
		color: #38cb89;
	}

	.failed {
		color: #ff5630;
	}

	.pi {
		height: 55px;
		width: 55px;
		background: tint($secondary, 85%);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $secondary;
		box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
	}

	.profile-form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 8rem;
		row-gap: 2em;
	}

	.p-menuitem-link.p-menuitem-link-active > .p-menuitem-text {
		color: #2d98d4 !important;
	}

	.payment-history-icon {
		background: url('../../../assets/icons/linear/billing-icon.svg');
	}

	.certification-history-icon {
		background: url('../../../assets/icons/linear/certification-history-icon.svg');
	}

	.profile-icon {
		background: url('../../../assets/icons/linear/profile-icon.svg');
	}

	.exam-scores-icon {
		background: url('../../../assets/icons/linear/exam-scores-icon.svg');
	}

	.p-menu .p-menuitem-link .p-menuitem-icon {
		margin-right: 1rem;
	}

	.certification-history-icon,
	.exam-scores-icon,
	.payment-history-icon,
	.profile-icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 2.5rem;
		width: 2.5rem;
	}

	.router-item {
		flex-grow: 1;
	}

	.member-id-card {
		color: #ffffff;
		background-color: #88c4e7;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		border-radius: 1rem;
		padding: 2rem 4rem;
	}

	.profile-padding {
		padding: 8rem 15rem;
	}
	// Overriding default tabmenu styling to remove top border and extra padding
	.p-component.p-tabmenu .p-tabmenu-nav {
		border-width: 0 0 2px 0;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}
	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}
	@media (max-width: 1500px) {
		.profile-padding {
			padding: 8rem 4rem;
		}
	}

	@media (max-width: 1360px) {
		.profile-form {
			column-gap: 2rem;
		}
	}

	@media (max-width: 650px) {
		.profile-form {
			grid-template-columns: 1fr;
		}
	}

	@include media-breakpoint-down(xl) {
		.profile-padding {
			padding: 4rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.profile-padding {
			padding: 4rem 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.testing-site-btn {
			button {
				width: 100%;
			}
		}

		.billing-btns,
		.profile-info-btns {
			p-button {
				width: 100%;
			}

			button {
				width: 100%;
			}

			span {
				width: 100%;
				text-align: center;
			}
		}
	}
}
