app-selected-certification {
	.selected-certification-header {
		display: grid;
		position: relative;

		.header-content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			color: #ffffff;
			z-index: 2;
			text-align: center;
		}
		* {
			grid-area: 1 / 1 / 2 / 2;
		}
	}

	.selected-certification-section {
		padding: 6rem 8rem;
		background: white;

		.selected-certification-container {
			display: flex;
			justify-content: space-between;
			gap: 3rem;
		}

		.list-container {
			.to-do-list-container {
				.card {
					border-radius: 8px;
					background: #fafbfc;
					border: 1px solid #e9ecef;

					.circle {
						min-height: 24px;
						min-width: 24px;
						border-radius: 50%;
						border: 1px solid #2d98d4;
					}

					.circle-without-check {
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 24px;
						min-width: 24px;
						border-radius: 50%;
						border: 1px solid #2d98d4;
					}

					.circle-with-check {
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 24px;
						min-width: 24px;
						border-radius: 50%;
						background: #2d98d4;
						color: white;

						i {
							font-size: 0.7rem;
							font-weight: 900;
						}
					}
				}
			}
		}

		.extra-info-container {
			width: 19.6rem;
			.card {
				border-radius: 12px;
				background: #fafbfc;
				border: 1px solid #e9ecef;
			}
			.cert-handbook-container {
				.p-progress-spinner {
					height: 18px;
					width: 18px;
				}
			}
			.premium-study-guide-card {
				.top-half {
					border-top-right-radius: 12px;
					border-top-left-radius: 12px;
					background-color: #e9ecef;
					text-align: center;
				}
				.bottom-half {
					border-bottom-right-radius: 12px;
					border-bottom-left-radius: 12px;
					text-align: center;
					border: 1px solid #e9ecef;
				}
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.selected-certification-section {
			padding: 3rem 1rem;
		}
	}

	@include media-breakpoint-down(lg) {
		.selected-certification-container {
			flex-direction: column;
			align-items: center;
			.list-container {
				.to-do-list-container {
					margin-bottom: 5rem;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.certified-btns {
			width: 100%;
			.p-button {
				width: 100%;
			}
		}
	}
}
