app-content {
	.page-title {
		padding: 1.5rem 2rem;
		width: 100%;
		background-color: white;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.show {
		background: #e9ecef;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
		border-radius: 16px;
	}

	.three-dot-icon {
		width: 40px;
		text-align: center;

		:hover {
			background: #e9ecef;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
			border-radius: 16px;
		}
	}

	.mobile-filters {
		display: none;
	}

	.course-settings-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 1rem;
		column-gap: 2rem;
	}

	.content-type-selection-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 1rem;
		column-gap: 1rem;
	}

	.create-flashcard-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 1rem;
		column-gap: 2rem;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: block;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}

	@media screen and (max-width: 700px) {
		.session-block-type {
			width: 100%;
		}

		.session-block-break {
			display: none;
		}

		.session-block-name {
			width: 100%;
			margin-top: 0.5rem;
			margin-left: 1rem;
		}

		.content-type-selection-grid {
			grid-template-columns: repeat(2, 1fr);
		}

		.create-flashcard-grid {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	@media screen and (max-width: 650px) {
		.course-settings-grid {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	@media screen and (max-width: 600px) {
		.new-session-btn {
			width: 100%;
			margin-top: 1rem;

			.p-button {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 505px) {
		.mobile-filters,
		.create-btn {
			width: 100%;
			margin-bottom: 0.5rem;

			.p-button {
				width: 100%;
			}
		}
	}
}
