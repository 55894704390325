app-faq {
	.faq-header {
		color: #2d98d4;
	}
	.faq-toggle-button {
		cursor: pointer;
		color: #2d98d4;
	}

	.grey-text {
		color: #667085;
	}

	.faq-container {
		width: 70rem;
	}

	.white-bg {
		background-color: #fff;
	}

	.questions-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: #ffffff;
		width: 100%;
		border-radius: 2rem;
		border: 1px solid #e9ecef;
	}

	.about-footer-container {
		background-color: #ffffff;
		padding: 4rem;
		overflow: hidden;
	}

	.p-accordion .p-accordion-header .p-accordion-header-link {
		border: none;
		background: transparent !important;
		gap: 1rem;
	}

	.p-accordion .p-accordion-content {
		border: none;
		background: transparent !important;
	}

	.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
		box-shadow: none !important;
	}

	.p-accordion-toggle-icon-end {
		color: #2d98d4;
	}

	@media (max-width: 750px) {
		.get-in-touch-button > .p-button,
		.header-button > .p-button {
			width: 100%;
		}
	}
}
