app-question-pool {
	.clear-p-chip > div {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.p-chip {
		background-color: #2d98d4;
		color: #ffffff;
	}
}
