app-training-program {
	.training-program-header {
		font-weight: 600;
	}

	.add-course-info-container {
		background-color: #fafbfc;
	}

	.course-info-icon {
		cursor: pointer;
		color: #667085;
	}

	.p-inputnumber {
		width: 100%;
	}

	.exam-name {
		font-size: 1.5rem;
		font-weight: 600;
		color: #2d98d4;
	}
}
