app-retake-confirmation {
	.summary-card {
		background-color: #fafbfc;
		width: 28rem;
	}
	.green-check-icon {
		color: #38cb89;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 5px #e5f6ef;
	}

	.p-button {
		width: 28rem;
	}

	@include media-breakpoint-down(md) {
		.summary-card,
		.p-button {
			width: 100%;
		}
	}
}
