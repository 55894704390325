app-partner-selected-student {
	.selected-student-container {
		padding: 7rem;
	}
	.top-bar {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	// Success button on partner view if accommodations are granted
	.p-button.p-button-success {
		color: #38cb89;
		background-color: #e5f6ef;
		border: 1px solid #e5f6ef;
	}

	.p-button.p-button-success:enabled:hover {
		color: #38cb89;
		background-color: shade(#e5f6ef, 5%);
		border-color: #e5f6ef;
	}

	.p-button.p-button-success.p-button-outlined {
		color: #e5f6ef;
		background-color: transparent;
		border: 1px solid #e5f6ef;
	}

	.p-button.p-button-success.p-button-outlined:enabled:hover {
		color: #38cb89;
		background-color: #e5f6ef;
		border-color: #e5f6ef;
	}

	.p-button.p-button-success.p-button-text {
		color: #e5f6ef;
		background-color: transparent;
		border: none;
		text-decoration: underline;
	}

	.p-button.p-button-success.p-button-text:enabled:hover {
		color: #38cb89;
		background: #e5f6ef;
		text-decoration: underline;
	}

	// Overriding default tabmenu styling to remove top border and extra padding
	.p-component.p-tabmenu .p-tabmenu-nav {
		border-width: 0 0 2px 0;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}
	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}
	.p-component.p-tabmenu .p-tabmenu-nav {
		padding: 0;
	}

	@media screen and (max-width: 654px) {
		.grant-accommodations-btn {
			margin-top: 1rem;
		}
	}

	@media (max-width: 570px) {
		.top-bar {
			display: none;
		}

		.selected-student-container {
			padding: 1rem;
		}
	}
}
