.p-togglebutton.p-button {
	width: 8rem;
	height: 8rem;
	padding: 5px;

	&.p-highlight {
		background: white;
		border: 2px solid $secondary;
		color: $secondary;

		.p-button-icon-left {
			color: $secondary;
			font-weight: 800;
		}
	}

	&.p-highlight:hover {
		background: $secondary;
		border-color: $secondary;
	}

	.p-button-icon-left {
		position: absolute;
		top: 7px;
		margin-right: 0;
	}
}
