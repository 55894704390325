app-partner-registrations {
	.mobile-filters {
		display: none;
	}

	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: block;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (max-width: 400px) {
		.mobile-filters,
		.export-csv-btn {
			width: 100%;

			.p-button {
				width: 100%;
			}
		}

		.export-csv-btn {
			margin-top: 0.5rem;
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}
}
