app-resources {
	.topical-guide-card-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		justify-content: center;
		row-gap: 3rem;
		column-gap: 2rem;
	}

	.resources-container {
		padding: 2rem 4rem;
	}

	.topical-guide-card {
		border-radius: 2rem;
		border: 1px solid #e9ecef;
		padding: 1rem;
		text-align: center;
	}

	.slider-card {
		border-radius: 2rem;
		border: 1px solid #e9ecef;
	}
	.free-topical-guide-header,
	.recommended-training-courses-header,
	.arrow {
		color: #2d98d4;
	}

	.download-partner-handbook {
		cursor: pointer;
		color: #2d98d4;
		text-decoration: underline;
	}

	@media (max-width: 759px) {
		.topical-guide-card-container {
			row-gap: 2rem;
		}

		.resources-container {
			padding: 2rem 1rem;
		}
	}
}
