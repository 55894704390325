.p-calendar {
	.p-inputtext {
		border-right: none;
	}

	.p-button {
		background: white;
		color: $secondary;
		border: 1px solid #ced4da;
		border-left: none;

		.pi {
			font-size: 1.5rem;
		}
	}

	.p-button:enabled:hover {
		background: white;
		color: $secondary;
		border-color: #ced4da;
	}

	.p-inputtext:enabled:focus + .p-button {
		border-color: #3b82f6;
		border-right-color: none;
		border-left-color: none;
	}

	.p-inputtext:enabled:hover + .p-button {
		border-color: #3b82f6;
	}

	.p-inputtext:enabled:focus {
		box-shadow: none;
	}
	.p-datepicker-buttonbar .p-button {
		border: 1px solid #ced4da;
	}
}

.calendar-invalid-dirty {
	.p-inputtext,
	.p-button-icon-only {
		border-color: #e24c4c !important;
	}
}
