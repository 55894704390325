app-question-pool-set {
	.p-dropdown {
		width: 100%;
	}

	.checkbox-and-answer-container {
		border: 1px solid #ced4da;
		border-radius: 8px;
	}

	h3 {
		color: #2d98d4;
	}

	.edit-question:hover,
	.remove-question:hover {
		cursor: pointer;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	//Overriding default checkbox color
	.p-checkbox .p-checkbox-box.p-highlight {
		background: #e4f2f9;
		border-color: #2d98d4;
	}

	//Overriding default checkbox color
	.p-checkbox .p-checkbox-box .p-checkbox-icon {
		color: #2d98d4;
		font-weight: 600;
	}
}
