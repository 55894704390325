app-my-certifications {
	.arrow {
		padding: 1rem;
		padding-left: 8rem;
		align-items: center;
	}

	.my-active-certifications-table,
	.my-expired-certifications-table {
		padding: 6rem 8rem;
		background: white;
	}

	.earn-additional-certifications {
		padding: 6rem 8rem;
		background: white;

		.card {
			position: relative;
			max-width: 1300px;
			background: #fafbfc;
			margin: 0 auto;
			border: 1px solid #e9ecef;
			border-radius: 48px;
			height: 296px;
			justify-content: center;

			img {
				position: absolute;
				top: -50px;
				left: -57px;
				width: 30.1rem;
			}
		}
	}

	// use this if there is no pagination on table
	.base-table .p-datatable .p-datatable-tbody > tr:last-child > td {
		border-bottom: 0;
	}

	@include media-breakpoint-down(xl) {
		.my-active-certifications-table,
		.my-expired-certifications-table {
			padding: 3rem 2rem;
		}

		.earn-additional-certifications {
			padding: 3rem 2rem;

			.card {
				max-width: 100% !important;
				height: auto !important;

				img {
					width: 100% !important;
					position: initial !important;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.my-active-certifications-table,
		.my-expired-certifications-table {
			padding: 3rem 1rem;
		}

		.earn-additional-certifications {
			padding: 3rem 1rem;
			background: #fafbfc;

			.card {
				background: none !important;
				border: none !important;
			}

			.content {
				margin-bottom: 2rem;

				p {
					max-width: 100% !important;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.content {
			.p-button {
				width: 100% !important;
			}
		}

		.my-expired-certifications-table > div > p-button {
			.p-button {
				width: 100%;
			}
		}
	}
}
