app-billing-info {
	.edit-billing-info-form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		row-gap: 1rem;
		column-gap: 2rem;
	}

	.p-dialog {
		width: 50rem;
	}
}
