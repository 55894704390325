app-student-contact-us {
	.card-image-container {
		flex: 50%;
		position: relative;
		width: 100%;
		height: auto;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(15, 67, 96, 0.48)),
			url('../../../assets/images/student-pages/contact-us.jpeg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% center;
		display: flex;
		justify-content: center;
		align-items: center;

		.quote-card {
			border: 1px solid rgba(255, 255, 255, 0.5);
			background-color: rgba(255, 255, 255, 0.31);
			backdrop-filter: blur(10px);
			z-index: 2;
			margin: 1rem;
			min-width: 50%;
		}
	}
	.student-contact-us-container {
		display: flex;
	}

	.contact-us-form-container {
		flex: 50%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.form-side {
		min-width: 50%;
		width: 60%;
	}

	@media (max-width: 1200px) {
		.form-side {
			width: 80%;
		}
	}

	@media (max-width: 847px) {
		.student-contact-us-container {
			flex-direction: column;
		}

		.contact-us-form-container {
			padding: 1rem;
		}
		.form-side {
			width: 100%;
		}
	}
}
