app-navbar {
	nav {
		height: 5rem;
	}

	.nav-item {
		display: inline-block;
	}

	.p-avatar {
		background-color: $secondary;
		span.p-avatar-text {
			color: white;
			font-size: $font-size-base * 0.8125;
			font-weight: 700;
		}
	}

	a.dropdown-toggle {
		color: white;
		font-size: $font-size-base * 0.8125;
		.username {
			font-size: $font-size-base;
		}
	}

	.remove-dropdown-icon::after,
	.remove-dropdown-icon::before {
		content: none !important;
	}

	.navbar-nav .nav-link.active {
		color: $primary;
		background-color: white;
		padding-top: 1.8rem;
		padding-bottom: 1.8rem;
		padding: 1.8rem 1rem;
	}

	.student-nav-items {
		gap: 1rem;

		.dropdown-icon {
			transition: transform 0.5s ease-in-out;
		}

		.dropdown-icon.closed {
			transform: rotate(180deg);
		}

		.navbar-nav {
			color: $secondary;
		}
	}

	.navbar-light {
		border-bottom: 1px solid #e9ecef;
		.navbar-nav {
			.nav-item {
				align-self: center;
				position: relative;

				.text-wrap {
					background: white;
					border: 1px solid #e9ecef;
					border-radius: 0px 0px 16px 16px;
					box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
					position: fixed;
					padding: 1rem 1rem 0.5rem 1rem !important;
					top: 5rem;
					z-index: 5;

					a.dropdown-item {
						margin-bottom: 1rem;
					}
				}
			}
			.nav-link {
				&.active {
					color: $secondary;
					padding: 0.5rem;
				}

				.username {
					color: $secondary;
				}

				.userrole {
					color: #667085;
				}
			}
		}

		.p-avatar {
			background-color: #e4f2f9;

			.p-avatar-text {
				color: $secondary;
			}
		}

		.navbar-toggler {
			border: none;
		}
	}

	.browse-certifications-icon {
		background: url('../../assets/icons/student-menu/browse-certifications-icon.svg');
	}

	.my-certifications-icon {
		background: url('../../assets/icons/student-menu/my-certifications-icon.svg');
	}

	.renew-certification-icon {
		background: url('../../assets/icons/student-menu/renew-certification-icon.svg');
	}

	.get-ces-icon {
		background: url('../../assets/icons/student-menu/get-ces-icon.svg');
	}

	.my-ces-icon {
		background: url('../../assets/icons/student-menu/my-ces-icon.svg');
	}

	.import-ces-icon {
		background: url('../../assets/icons/student-menu/import-ces-icon.svg');
	}

	.browse-certifications-icon,
	.my-certifications-icon,
	.renew-certification-icon,
	.get-ces-icon,
	.my-ces-icon,
	.import-ces-icon {
		height: 2.5rem;
		width: 2.5rem;
		margin-right: 1rem;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}
