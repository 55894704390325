app-certification-exam {
	.arrow {
		padding: 1.5rem;
		padding-left: 6rem;
	}
	.exam-container {
		padding: 1rem 6rem;
		background-color: #ffffff;
		.question-card {
			background-color: #fafbfc;
		}
		.test-container {
			display: flex;
			gap: 4rem;
			.mobile-screen {
				display: none;
			}
		}
		.cert-question,
		.cert-answer {
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			-o-user-select: none;
			user-select: none;
		}

		@include media-breakpoint-down(md) {
			.test-container {
				flex-direction: column;
				.mobile-screen {
					display: block;
					margin-bottom: 3rem;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.exam-container {
			padding: 2rem;
		}
	}
}
