app-retake-summary {
	.summary-container {
		max-width: 30%;

		.card {
			background: #fafbfc;
			border: 1px solid #e4e9f0;

			.card-body {
				padding: 1.5rem;
			}
		}

		.p-inputgroup {
			button {
				border-radius: 0 6px 6px 0;
			}
		}
	}

	.stripe-checkout {
		max-width: 330px;
		width: 100%;

		.card {
			background: #fafbfc;
			border: 1px solid #e4e9f0;

			.card-body {
				padding: 1.5rem;
			}
		}
	}
}
