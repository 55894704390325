app-discount-codes {
	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.show {
		background: #e9ecef;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
		border-radius: 16px;
	}

	.three-dot-icon {
		width: 40px;
		text-align: center;

		:hover {
			background: #e9ecef;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
			border-radius: 16px;
		}
	}

	.p-dialog {
		width: 444px;
	}
}
