app-chart {
	.mini-card {
		padding: 0 0.75rem;
		margin-bottom: 1.5rem;
	}

	.mini-card-container {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
	}

    .app-chart {
        @include media-breakpoint-up(md) {
            // max-height: 880px;
        }

        h6 {
            margin: 0;
        }
    }
    app-base-chart {
        // max-height: 880px;
    }

    .mobile-filters {
		display: none;
	}
    @media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}
    @media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: flex;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}
        }
    }
    @media screen and (max-width: 500px) {
		.mobile-filters {
			width: 100%;

			.p-button {
				width: 100%;
			}
		}
    }
}

