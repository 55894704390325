app-edit-application {
	.checkbox-group,
	.point-of-contact-form {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 2rem;
	}

	.payment-container,
	.point-of-contact-container {
		margin-top: 5rem;
	}

	.point-of-contact-form,
	.designated-billing-administrator {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 1rem;
		column-gap: 2rem;
	}

	.p-dropdown,
	.p-inputnumber {
		width: 100%;
	}

	.course-info-icon {
		cursor: pointer;
		color: #667085;
	}

	.title,
	.point-of-contact-header,
	.exam-name {
		color: #2d98d4;
	}

	.exam-name {
		font-size: 1.5rem;
		font-weight: 600;
	}
	.status-circle {
		color: #e9ecef;
	}
	.status-container {
		width: 22rem;
		margin-left: 3rem;
	}

	.status-type-text {
		color: #2d98d4;
	}
	.section-description,
	.payment-description {
		color: #667085;
	}

	.payment-description {
		margin-left: 2.3rem;
	}

	.training-program-header {
		font-weight: 600;
	}

	.add-course-info-container {
		background-color: #fafbfc;
	}

	input:read-only,
	.p-dropdown.p-disabled,
	.disabledDropdown {
		opacity: 0.6;
	}

	.disabledTrainingProgram {
		background-color: #e4f2f94d;
	}

	.back-arrow-icon {
		cursor: pointer;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	.p-dialog-header {
		text-align: center;
	}

	@media screen and (max-width: 1350px) {
		.checkbox-group,
		.point-of-contact-form {
			grid-template-columns: repeat(2, 1fr);
		}

		.point-of-contact-form,
		.designated-billing-administrator {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media screen and (max-width: 830px) {
		.checkbox-group,
		.point-of-contact-form {
			grid-template-columns: repeat(1, 1fr);
		}

		.point-of-contact-form,
		.designated-billing-administrator {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
