.video-player-dialog {
	max-width: 50rem !important;
	width: 100%;
	.p-dialog-header {
		.p-dialog-header-icons {
			display: none;
		}
	}
}

.custom-dialog-header {
	.p-dialog-content {
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
		border-bottom-left-radius: 2rem !important;
		border-bottom-right-radius: 2rem !important;
	}
}
