app-partner-profile-info {
	.edit-partner-form {
		grid-template-columns: repeat(auto-fit, minmax(350px, 2fr));
		gap: 1rem;
	}

	.p-dropdown {
		width: 100%;
	}

	.p-dialog .p-dialog-content:last-of-type {
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

	#organizationType .p-disabled,
	#organizationType .p-component:disabled,
	#partnerCountry .p-disabled,
	#partnerCountry .p-component:disabled,
	#partnerState .p-disabled,
	#partnerState .p-component:disabled {
		opacity: 1;
		background-color: #fafbfc;
	}

	@media screen and (min-width: 1850px) {
		.edit-partner-form {
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
		}
	}
}
