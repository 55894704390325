app-browse-ce-courses {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		align-items: center;
	}

	.browse-ce-courses-header {
		padding: 6rem 8rem;
		background: white;

		.ce-registration-code-card {
			border-radius: 2rem;
			padding: 0px 48px 0px 0px;
			background-color: #2d98d4;
			display: flex;
			align-items: center;
			min-width: 40rem;
			max-width: 60rem;

			.p-inputtext {
				max-width: 250px;
				width: 100%;
			}
		}
	}

	.browse-ce-course-cards {
		background: white;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(379px, 1fr));
		row-gap: 1.5rem;
		column-gap: 1rem;
		padding: 6rem 8rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	@include media-breakpoint-down(xl) {
		.browse-ce-courses-header {
			padding: 4rem 2rem;

			.ce-registration-code-card {
				max-width: 100%;
			}
		}

		.browse-ce-course-cards {
			padding: 0rem 2rem 5rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.browse-ce-courses-header {
			padding: 3rem 1rem;

			.ce-registration-code-card {
				padding: 2rem !important;
				min-width: 100% !important;
			}
		}

		.browse-ce-course-cards {
			padding: 0rem 1rem 5rem 1rem;
			display: block;
			width: 100%;

			.card {
				margin-bottom: 1.5rem;
			}
		}
	}
}
