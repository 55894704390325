.base-table .p-datatable {
	th:first-child {
		border-top-left-radius: 1rem;
	}

	.p-datatable-wrapper {
		border-top-right-radius: 1rem;
		border-top-left-radius: 1rem;
	}

	th:last-child {
		border-top-right-radius: 1rem;
	}
	.p-datatable-tbody > tr > td {
		color: $sideBarText;
		a {
			color: $secondary;
		}

		.trash-icon[role='button'] {
			color: $sideBarText;
		}

		.trash-icon[role='button']:hover {
			color: $primary;
		}
	}

	.p-datatable-thead > tr {
		background-color: $light;
		border: solid rgba(0, 0, 0, 0.175);
		border-width: 0px 0px 1px 0px;
		> th {
			color: $sideBarText;
			background-color: transparent;
			padding-top: 1rem;
			padding-bottom: 1rem;
			font-weight: 400;
		}
	}

	.p-paginator {
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}

	.p-dropdown {
		width: 100%;
	}

	@media screen and (max-width: 960px) {
		.p-datatable-wrapper {
			border-radius: 1rem;

			.p-datatable-thead {
				display: none;
			}
		}

		.p-datatable-tbody > tr > td {
			flex-wrap: wrap;
			justify-content: center !important;

			.p-column-title {
				width: 100%;
				text-align: center;
				color: black;
			}

			.text-data {
				width: 100%;
				text-align: center;
			}
		}
	}
}
