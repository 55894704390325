app-renew-certification {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		align-items: center;
	}

	.renew-certification {
		padding: 6rem 8rem;
		background: white;

		.p-dialog-header {
			.question-icon {
				height: 55px;
				width: 55px;
				background: tint($secondary, 85%);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				color: $secondary;
				box-shadow: 0px 0px 0px 10px tint($secondary, 95%);

				i {
					font-size: 1.6rem;
				}
			}
		}

		.p-steps {
			margin: 5rem 0rem;
			.p-steps-item {
				&::before {
					margin-top: 0rem !important;
				}

				.p-menuitem-link {
					cursor: pointer;
					.p-steps-number {
						border-color: #2d98d4;
						color: #2d98d4;
					}

					.p-steps-title {
						display: none;
					}
				}

				&.p-highlight {
					.p-menuitem-link {
						box-shadow: 0 0 0 8px #e4f2f9;
						border-radius: 50%;

						&:not(.p-disabled):focus {
							box-shadow: 0 0 0 8px #e4f2f9;
						}

						.p-steps-number {
							background: #2d98d4;
							color: white;
						}
					}
				}
			}
		}

		.active-step-1 {
			.p-steps-item:nth-child(1) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-2 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-3 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2),
			.p-steps-item:nth-child(3) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-4 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2),
			.p-steps-item:nth-child(3),
			.p-steps-item:nth-child(4) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.renew-certification-step-three,
		.renew-certification-step-four {
			.total-cost-container {
				max-width: 330px;
				width: 100%;

				.card {
					background: #fafbfc;
					border: 1px solid #e4e9f0;

					.card-body {
						padding: 1.5rem;
					}
				}

				.p-inputgroup {
					button {
						border-radius: 0 6px 6px 0;
					}
				}
			}
		}

		.renew-certification-step-four .pi-check-icon {
			width: 30px;
			height: 30px;
			background: tint(#38cb89, 90%);
			border-radius: 50%;
			position: relative;

			i {
				font-weight: bold;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 0.8rem;
				color: #38cb89;
				padding: 0.2rem;
				background: tint(#38cb89, 80%);
				border-radius: 50%;
			}
		}

		.your-ce-credits {
			max-width: 315px;
			width: 100%;

			.card {
				border-radius: 24px;
				background: #fafbfc;
			}
		}

		.p-checkbox {
			width: 30px;
			height: 30px;
			.p-checkbox-box {
				width: 30px;
				height: 30px;
				border: 1px solid #2d98d4;
				border-radius: 50%;
				position: relative;

				&.p-highlight {
					background: #2d98d4;
				}

				&.p-highlight::before,
				&.p-highlight::after {
					content: '';
					position: absolute;
					background-color: #ffffff;
				}

				&.p-highlight::before {
					top: 48%;
					left: 33%;
					width: 15px;
					height: 2.7px;
					transform: rotate(-52deg);
				}

				&.p-highlight::after {
					top: 55.9%;
					left: 23%;
					width: 7px;
					height: 2.7px;
					transform: rotate(37.7deg);
				}

				.p-icon-wrapper {
					display: none;
				}
			}
		}

		.base-table .p-datatable .p-datatable-tbody > tr:last-child > td {
			border-bottom: 0;
		}
	}

	.renew-certification-links {
		padding: 6rem 8rem;
		display: flex;
		justify-content: space-evenly;
		gap: 3rem;

		p {
			line-height: 1.2;
		}

		> div {
			max-width: 400px;
		}
	}

	@include media-breakpoint-down(xl) {
		.renew-certification,
		.renew-certification-links {
			padding: 2rem 3rem;
		}
	}

	@include media-breakpoint-down(lg) {
		.renew-certification-links {
			justify-content: space-between;
		}
	}

	@include media-breakpoint-down(md) {
		.renew-certification {
			padding: 1rem 2rem;

			.your-ce-credits {
				max-width: 100%;
			}
		}

		.renew-certification-links {
			padding: 1rem 2rem;
			flex-direction: column;
			align-items: center;
			gap: 3rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.renew-certification-links {
			padding: 2rem 1rem;

			.p-button {
				width: 100%;
			}
		}

		.renew-certification {
			.renew-certification-step-one,
			.renew-certification-step-two {
				.p-button {
					width: 100%;
				}
			}
		}
	}
}
