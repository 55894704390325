app-approval {
	.page-title {
		padding: 1.5rem 2rem;
		width: 100%;
		background-color: white;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.p-menuitem-link > .p-menuitem-badge {
		background: #2d98d4 !important;
	}

	.p-input-icon-right > i:last-of-type {
		color: $secondary;
	}

	.p-calendar {
		width: 10rem;
	}

	.approve-icon .p-button-icon-left {
		color: #38cb89;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 5px #e5f6ef;
	}

	.deny-icon .p-button-icon-left {
		color: #f44336;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #ffebee;
		box-shadow: 0px 0px 0px 5px #ffebee;
	}

	.mobile-filters {
		display: none;
	}

	.p-dialog .p-dialog-header {
		padding: 0.75rem 1.5rem;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: block;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.mobile-filters {
			width: 100%;

			.p-button {
				width: 100%;
			}
		}

		.cancel-btn {
			width: 100%;
			text-align: center;
			margin-bottom: 0.5rem;
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}
}
