app-test-attempts {
	.header-icon {
		font-size: 1.5rem;
	}

	.exam-name {
		color: #002433;
		font-size: 1.2rem;
	}
}
