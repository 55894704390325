@font-face {
    font-family: 'SofiaPro';
    src: url('../assets/fonts/SofiaProBold/font.woff2') format('woff2'),
        url('../assets/fonts/SofiaProBold/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaPro';
    src: url("../assets/fonts/SofiaProSemiBold/font.woff2") format('woff2'),
        url("../assets/fonts/SofiaProSemiBold/font.woff") format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaPro';
    src: url('../assets/fonts/SofiaProMedium/font.woff2') format('woff2'),
        url('../assets/fonts/SofiaProMedium/font.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaPro';
    src: url("../assets/fonts/SofiaProRegular/font.woff2") format('woff2'),
    url("../assets/fonts/SofiaProRegular/font.woff") format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaPro';
    src: url("../assets/fonts/SofiaProLight/font.woff2") format('woff2'),
        url("../assets/fonts/SofiaProLight/font.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}