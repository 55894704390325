app-student-study-guides {
	.slider-card {
		border-radius: 2rem;
		background-color: #ffffff;
		padding: 1rem;
		margin: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.slider-card-name {
		font-size: 1.2rem;
	}
	.carousel-container {
		padding: 2rem 1rem;
		margin: 4rem 0;
		border-radius: 2rem;
	}

	.study-guides-container {
		padding: 6rem 8rem;

		.study-guide-code-card {
			border-radius: 2rem;
			padding: 0px 48px 0px 0px;
			background-color: #2d98d4;
			display: flex;
			align-items: center;
			min-width: 40rem;
			max-width: 60rem;

			.p-inputtext {
				max-width: 250px;
				width: 100%;
			}
		}
	}

	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.topical-guide-card-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		justify-content: center;
		row-gap: 3rem;
		column-gap: 2rem;
		margin-top: 5rem;
	}
	.topical-guide-card {
		border-radius: 2rem;
		border: 1px solid #e9ecef;
		padding: 1rem;
		text-align: center;
	}

	@include media-breakpoint-down(xl) {
		.study-guides-container {
			padding: 3rem;

			.study-guide-code-card {
				max-width: 100%;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.study-guides-container {
			padding: 1rem;

			.study-guide-code-card {
				padding: 2rem !important;
				min-width: 100% !important;
			}
		}
	}

	@media (max-width: 700px) {
		.carousel-container {
			padding: 0;
		}
	}

	@media (max-width: 545px) {
		.slider-card {
			flex-direction: column;
			img {
				margin-right: 0 !important;
				margin-bottom: 0.5rem;
			}
		}
	}
}
