app-individual-study-guide {
	.arrow {
		padding: 1rem;
		padding-left: 6rem;
		align-items: center;
	}

	.study-guide-container {
		.title-section {
			padding: 6rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 4rem;
			gap: 5rem;
		}

		.overview-section {
			padding: 6rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 10rem;

			.overview-card {
				background-color: #fafbfc;
				padding: 1.5rem;
				border-radius: 2rem;
				width: 100%;
				max-width: 650px;
				margin-bottom: 2rem;
			}
		}

		.pricing-section {
			padding: 6rem 12rem;
			background-color: #fafbfc;
			display: flex;
			justify-content: space-between;
			gap: 3rem;
			align-items: center;

			.pricing-card {
				padding: 3rem;
				border: 1px solid #e9ecef;
				background-color: #ffffff;
				max-width: 25rem;
				width: 100%;
				border-radius: 2rem;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.study-guide-container {
			.title-section,
			.overview-section,
			.pricing-section {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.pricing-section {
			flex-direction: column-reverse;
		}

		.title-section {
			flex-direction: column;
		}
	}
}
