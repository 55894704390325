app-import-external-ces {
	.arrow {
		padding: 1rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.import-ces-container {
		padding: 6rem 8rem;
		background-color: #ffffff;
	}

	.header {
		margin-bottom: 5rem;
		color: #2d98d4;
	}

	.flex-container {
		display: flex;
		justify-content: space-between;
		gap: 3rem;
		max-width: 100rem;
	}

	.form-container {
		border: 1px solid #e9ecef;
		border-radius: 3rem;
		padding: 3rem;
	}

	.form-side {
		max-width: 588px;
		width: 100%;
	}

	.requirements-side {
		max-width: 30rem;
		height: 100%;
		max-height: 35rem;
		padding: 2rem;
		border: 1px solid #e9ecef;
		border-radius: 2rem;
		background-color: #fafbfc;
	}

	.p-inputnumber,
	.p-calendar {
		width: 100%;
	}

	li {
		color: #667085;
	}

	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	@media (max-width: 1500px) {
		.import-ces-container {
			padding: 6rem 3rem;
		}

		.arrow {
			padding-left: 3rem;
		}
	}
	@media (max-width: 1200px) {
		.form-side {
			max-width: 100%;
		}

		.form-container {
			max-width: 100%;
		}
		.import-ces-container {
			padding: 2rem 1rem;
		}
		.arrow {
			padding-left: 1rem;
		}
	}

	@media (max-width: 1000px) {
		.flex-container {
			flex-direction: column;
			align-items: center;
		}

		.requirements-side {
			max-width: 100%;
		}
	}

	@media (max-width: 500px) {
		.form-container {
			padding: 2rem;
		}

		.arrow {
			display: none;
		}
	}
}
