app-partner-profile-login-info {
	.partner-login-info {
		padding: 2.2rem;
	}

	.form-input {
		flex-grow: 1;
	}
	.p-dialog .p-dialog-content:last-of-type {
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

	.pi {
		height: 55px;
		width: 55px;
		background: tint($secondary, 85%);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $secondary;
		box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
	}

	@include media-breakpoint-down(xl) {
		.partner-login-info {
			padding: 1.5rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.login-info-btns {
			button {
				width: 100%;
			}
		}
	}
}
