app-student-account-profile {
	.form-input {
		flex-grow: 1;
	}

	#country .p-disabled,
	#country .p-component:disabled {
		opacity: 1;
		background-color: #fafbfc;
	}
}
