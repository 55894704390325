app-ce-courses-in-progress {
	.new-ce-course-dialog .p-dialog {
		width: 40rem;
	}

    .table-button-text {
        color: $secondary;
        text-decoration: underline;
        background-color: transparent; 
        border: none;
    }

    .checkbox-label {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;
    }

    .p-dialog.enrollment-progress-dialog .p-dialog-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        justify-content: start;
        padding-bottom: 1rem;
    }
}
