app-register-with-code-main {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.registration-steps {
		padding: 6rem 8rem;
		background: white;

		.p-steps {
			margin: 5rem 0rem;
			.p-steps-item {
				&::before {
					margin-top: 0rem !important;
				}

				.p-menuitem-link {
					cursor: pointer;
					.p-steps-number {
						border-color: #2d98d4;
						color: #2d98d4;
					}

					.p-steps-title {
						display: none;
					}
				}

				&.p-highlight {
					.p-menuitem-link {
						box-shadow: 0 0 0 8px #e4f2f9;
						border-radius: 50%;

						&:not(.p-disabled):focus {
							box-shadow: 0 0 0 8px #e4f2f9;
						}

						.p-steps-number {
							background: #2d98d4;
							color: white;
						}
					}
				}
			}
		}

		.active-step-1 {
			.p-steps-item:nth-child(1) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-2 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.active-step-3 {
			.p-steps-item:nth-child(1),
			.p-steps-item:nth-child(2),
			.p-steps-item:nth-child(3) {
				.p-menuitem-link {
					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}

		.registration-code {
			input {
				max-width: 20rem;
				width: 100%;
			}
		}

		.select-course {
			.input-container {
				max-width: 20rem;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.registration-steps {
			padding: 3rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.registration-steps {
			padding: 3rem 1rem;
		}
	}
}
