app-certification-exam-details {
	.assessment-details-container {
		position: sticky;
		top: 2rem;

		.progress-big-screen {
			display: flex;
			align-items: center;
		}

		.mobile-container {
			display: none;
		}

		@include media-breakpoint-down(md) {
			position: static;
			width: auto;

			.mobile-container {
				margin-left: -30px;
				width: 100%;
				display: block;
				position: fixed;
				bottom: 0;
				background-color: #ffffff;
				z-index: 100;
				.remaining-time-mobile {
					text-align: center;
					border: none;
				}
			}

			.big-screen {
				display: none;
			}

			.progress-mobile-screen {
				display: flex;
				align-items: center;
				margin: 1rem 0;
				padding: 0 2rem;
				gap: 0.5rem;
			}
		}
	}

	.assessment-results-container {
		position: fixed;
		width: 21%;

		@include media-breakpoint-down(md) {
			position: fixed;
			width: auto;
		}
	}

	.p-progressbar {
		height: 0.8rem;
		.p-progressbar-value {
			background-color: #2d98d4;
		}
		.p-progressbar-label {
			display: none !important;
		}
	}
}
