.p-steps {
	// Override default style to have line in middle of step number
	.p-steps-item::before {
		margin-top: -0.5rem !important;
	}

	// Adds color to completed steps
	.p-steps-item.p-steps-item-complete .p-steps-number {
		background-color: #2d98d4 !important;
		color: #ffffff !important;
	}
}
