app-users {
	*::-webkit-scrollbar {
		display: none;
	}

	.dropdown-toggle:hover {
		cursor: pointer;
	}

	.dropdown-toggle::after {
		display: none;
	}

	.p-button-link > .p-button-label {
		color: #2d98d4;
		text-decoration: underline;
	}

	.p-button-info > .p-button-label,
	.p-button.p-button-info.p-button-outlined {
		color: #2d98d4;
	}

	.show {
		background: #e9ecef;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.03);
		border-radius: 16px;
	}

	.d-inline-block {
		width: 25px;
		text-align: center;
	}

	.card-body {
		padding: 0.25rem;
	}

	.base-table .p-datatable .p-datatable-thead > tr > th {
		padding: 12px;
	}
	.body-container {
		display: flex;
		flex-direction: column;
		padding-top: 0.5rem;
	}
	.p-dialog .p-dialog-content:last-of-type {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
		padding: 2rem;
	}
	.p-dialog {
		width: 60rem;
		height: 22rem;
	}
	#recipient,
	#trigger {
		border: none;
		padding: 0;
	}
	.submit-edit-auto-email {
		background-color: #2d98d4;
	}
	.p-dropdown {
		width: 100%;
	}

	.addUserButton {
		background-color: #2d98d4;
	}
	/// Overriding the master scss file
	.p-dialog .p-dialog-content form label {
		color: #002032;
		font-size: 1rem;
	}

	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Setting row heights
	tr {
		line-height: 3.5rem;
	}

	.table-headers {
		border-bottom: 1px solid #dee2e6;
		padding: 8px;
		line-height: 1.5rem;
	}
}
