app-my-ces {
	.total-credits {
		border: 1px solid #e9ecef;
		background-color: #fafbfc;
		padding: 2rem;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;
		width: 20rem;
		hr {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.my-ces-tabmenu-items .p-tabmenu {
		.p-tabmenu-nav-content {
			padding: 0 0.5rem;
		}
		.p-tabmenu-nav {
			border-top: none;
			padding: 0;
		}

		.p-tabmenuitem:first-child {
			padding-left: 0;
		}
	}

	.arrow {
		padding: 1rem;
		padding-left: 8rem;
		align-items: center;
	}

	.my-ces-container {
		padding: 8rem;
		background-color: #ffffff;
		min-height: 100%;
	}

	.active-ext-ces-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
		margin: 4rem 0;
	}

	.my-ces-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #e9ecef;
		background-color: #fafbfc;
		border-radius: 2rem;
		max-height: 21rem;
		max-width: 70rem;
		margin-top: 6rem;
		padding: 1rem 1rem 0 1rem;
		img {
			max-height: 25rem;
			align-self: end;
			transform: translateX(-10%);
		}
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	.p-dialog .p-dialog-footer {
		text-align: center;
	}

	@include media-breakpoint-down(xl) {
		.my-ces-footer {
			flex-direction: column-reverse;
			align-items: center;
			max-height: 100%;
			max-width: 100%;
			gap: 3rem;
			.footer-right {
				align-items: center;
			}
			img {
				transform: translateX(0);
				align-self: center;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.active-ext-ces-container {
			flex-direction: column;
		}
	}

	@include media-breakpoint-down(md) {
		.total-credits {
			width: 100%;
		}

		.my-ces-footer {
			img {
				width: 100%;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.my-ces-container {
			padding: 5rem 1.5rem;
		}
		.active-ext-ces-container {
			align-items: initial;
			.p-button {
				width: 100%;
			}
		}

		.my-ces-footer {
			.footer-right {
				p-button,
				.p-button {
					width: 100%;
				}
			}
		}

		.ce-history-btn,
		.ce-history-btn > .p-button {
			width: 100%;
		}
	}
}
