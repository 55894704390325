app-student-support {
	.support-header {
		padding: 2rem 4rem;
	}

	img {
		border-radius: 1rem;
	}

	.video-tutorials-section {
		padding: 7rem;

		.video-tutorials-container {
			display: flex;
			gap: 2rem;
			width: 100%;

			.video-card {
				width: 100%;
				border-radius: 2rem;
				border: 1px solid #e9ecef;
				padding: 1rem 1rem;
				padding-bottom: 4rem;
				max-height: 32rem;

				img {
					max-height: 18rem;
					min-height: 18rem;
					object-fit: cover;
				}
			}
		}

		.video-tutorials-header {
			padding-bottom: 1rem;
		}
	}

	.student-faq-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 2rem;
		column-gap: 4rem;
	}

	.faq-section {
		background-color: #fafbfc;
		padding: 4rem;
	}

	.more-questions-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		border: 1px solid #e9ecef;
		max-width: 30rem;
		border-radius: 1rem;
	}

	.support-footer {
		background-color: #08192b;
		color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		padding: 2rem;
		text-align: center;
	}

	.video-tutorials-description {
		margin-bottom: 1.5rem;
	}

	.faq-item {
		max-width: 30rem;
	}

	@media (max-width: 950px) {
		.video-tutorials-container {
			flex-direction: column;
			align-items: center;
		}
		.student-faq-container {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (max-width: 891px) {
		.faq-item {
			max-width: 100%;
		}
	}

	@media (max-width: 800px) {
		.video-card {
			margin: 1rem;
			img {
				max-height: 20rem;
			}
		}

		.video-tutorials-section {
			padding: 0 1rem;
		}
		.faq-section,
		.video-tutorials-container {
			padding: 1rem;
		}
		.support-header {
			padding: 1rem;
		}
	}

	@media (max-width: 587px) {
		.video-tutorials-description {
			margin-bottom: 0.5rem;
		}
		.student-faq-container {
			grid-template-columns: 1fr;
		}
	}
}
