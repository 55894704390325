.p-fileupload {
	.p-button {
		background: $secondary;
		border-color: $secondary;
	}

	.p-button:enabled:hover {
		background: $secondary;
		border-color: $secondary;
	}
}
