app-about-aah-footer {
	.about-aah-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.about-text,
	.image-container {
		flex: 1 1 0;
	}

	.about-text {
		margin-right: 2rem;
	}

	.support-images {
		max-width: 100%;
		height: 100%;
	}

	@media (max-width: 1200px) {
		.about-aah-container {
			flex-direction: column;
		}
		.about-text {
			margin-right: 0;
			margin-bottom: 2rem;
		}
		.footer-button {
			text-align: center;
		}
	}

	@media (max-width: 750px) {
		.footer-button > .p-button {
			width: 100%;
		}
	}
}
