app-contact-us {
	.contact-form-container {
		width: 60rem;
		padding: 3rem 3rem;
		border-radius: 4rem;
	}

	#firstName,
	#lastName,
	#email {
		border-radius: 2rem;
	}

	.footer-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fafbfc;
	}

	.first-last-container {
		display: flex;
		justify-content: space-between;
	}

	.first-name-input {
		margin-right: 2rem;
	}

	.about-aah-footer-container {
		background-color: #ffffff;
	}

	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	.span-shadow {
		height: 55px;
		width: 55px;
		background: tint($secondary, 85%);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $secondary;
		box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
	}

	// for privacy policy modal
	.p-dialog-header {
		.p-dialog-header-icons {
			display: none;
		}
	}

	@media (max-width: 1200px) {
		.footer-container {
			flex-direction: column;
		}
	}

	@media (max-width: 1200px) {
		.contact-form-container {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	@media (max-width: 600px) {
		.first-name-input {
			margin-right: 0;
			margin-bottom: 2rem;
		}
		.first-last-container {
			flex-direction: column;
		}
	}

	@media (max-width: 500px) {
		.contact-form-container {
			padding: 1.5rem;
		}
	}
}
