app-reports {
	.reports-header {
		background: #ffffff;
		padding: 1.5rem 2rem;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.reports-container {
		padding-left: 2rem;
		padding-right: 2rem;
		display: block;
	}

	.report-button-group {
		display: flex;
		margin-top: 2.5rem;

		.report-button {
			min-width: 8rem;
		}
	}

	.mobile-filters {
		display: none;
	}
	.passed {
		color: #38cb89;
	}

	.failed {
		color: #ff5630;
	}

	.filters {
		flex-wrap: wrap;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.mobile-filters {
			display: block;
		}

		.report-button-group {
			margin: 0 !important;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.mobile-filters {
			width: 100%;
			margin-bottom: 0.5rem;

			.p-button {
				width: 100%;
			}
		}

		.report-button-group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 0.25rem;
			width: 100%;

			.p-button {
				width: 100%;
				margin: 0 !important;
			}
		}

		.filter-buttons-container {
			flex-wrap: wrap;
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}
}
