app-certification-list {
	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}
}
