app-global-exam-builder-create-edit {
	.test-questions-container {
		border: 1px solid #e9ecef;
		border-radius: 24px;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.p-dropdown,
	.p-inputnumber {
		width: 100%;
	}

	.title {
		color: #2d98d4;
	}

	.item-1,
	.item-2 {
		flex: 1 1 0px;
	}

	.x-icon {
		font-size: 1.3rem;
		cursor: pointer;
		color: #667085;
		margin-left: 5rem;
		margin-right: 1rem;
	}

	.exam-details-container,
	.test-container {
		padding: 4rem;
	}

	.card-container {
		margin-bottom: 3rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	// Sets the error border on duplicate question pool set dropdowns
	p-dropdown.duplicateQuestionPool:not(form) > div {
		border: 1px solid #d40000;
	}

	p-dropdown.ng-invalid.duplicateQuestionPool.ui-inputwrapper-focus:not(form) > div {
		box-shadow: 0 0 0 0.05em #f40000;
		border: 1px solid #d40000;
		border-radius: 2px;
	}

	p-dropdown.duplicateQuestionPool.ng-untouched.ui-inputwrapper-focus:not(form) > div {
		box-shadow: 0 0 0 0.05em #f40000;
		border: 1px solid #d40000;
		border-radius: 2px;
	}

	// Sets the error border on percent inputs
	p-inputnumber.ng-dirty.ng-invalid .ui-inputtext,
	p-inputnumber.ng-touched.ng-invalid .ui-inputtext {
		border: 1px solid #d40000;
		border-radius: 2px;
		box-shadow: 0 0 0 0.05em #f40000;
	}

	.test-questions-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
		width: 100%;
	}

	@media screen and (max-width: 850px) {
		.test-questions-grid {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	@media screen and (max-width: 576px) {
		.remove-set-btn {
			width: 100%;
			text-align: end;
			margin-top: 1rem;
		}

		.test-questions-grid {
			display: block;
		}
	}
}
