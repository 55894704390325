app-certification-exam-pretest {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
	}

	.pretest-container {
		padding: 7rem;
		background-color: #ffffff;

		.pretest-info {
			display: flex;
			justify-content: space-between;
			gap: 7rem;

			.tabview-side {
				max-width: 60%;
			}

			.tabview-side,
			.test-details {
				flex: 1;
			}
			.test-details {
				max-width: 27rem;
				max-height: 28em;
			}
		}
	}

	.agreement-dialog {
		.p-dialog {
			max-height: 40rem;
			.p-dialog-footer {
				padding-top: 1rem;
				p-button {
					width: 100%;
					button {
						width: 100%;
					}
				}
			}
		}
	}

	.agreement-question-mark {
		height: 55px;
		width: 55px;
		background: tint($secondary, 85%);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $secondary;
		box-shadow: 0px 0px 0px 10px tint($secondary, 95%);
	}

	@include media-breakpoint-down(md) {
		.pretest-container {
			padding: 3rem;
			.pretest-info {
				flex-direction: column;
				gap: 3rem;

				.tabview-side {
					max-width: 100%;
				}
				.test-details {
					max-width: 100%;
				}
			}
		}
	}
}
