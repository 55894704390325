app-partner-dashboard {
	.icon,
	.learn-more {
		color: #2d98d4;
	}

	.header-container {
		padding: 7rem;
	}

	.codes-section {
		padding: 7rem;
	}

	.action-cards-container {
		display: flex;
		gap: 1.5rem;
	}

	.quick-actions-section {
		padding: 7rem;

		.quick-actions-card {
			width: 100%;
			border-radius: 2rem;
			border: 1px solid #e9ecef;
			padding: 2rem;
			text-align: center;
			background-color: #ffffff;
		}

		.learn-more {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.video-tutorials-section {
		padding: 7rem;

		.video-tutorials-container {
			display: flex;
			gap: 2rem;
			width: 100%;

			.video-card {
				width: 100%;
				border-radius: 2rem;
				border: 1px solid #e9ecef;
				padding: 1rem 1rem;
				padding-bottom: 4rem;
				max-height: 32rem;

				img {
					max-height: 18rem;
					min-height: 18rem;
					object-fit: cover;
				}
			}
		}

		.video-tutorials-header {
			padding-bottom: 1rem;
		}
	}

	img {
		border-radius: 1rem;
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	@include media-breakpoint-down(md) {
		.video-tutorials-header {
			padding: 1rem;
		}

		.quick-actions-card {
			padding: 1rem;
		}

		.video-card {
			padding-bottom: 1rem;
		}
	}

	@media (max-width: 1199px) {
		.video-tutorials-section,
		.quick-actions-section {
			padding: 7rem 1rem;
		}
		.codes-section,
		.header-container {
			padding: 5rem 1rem;
		}
	}

	@media (max-width: 953px) {
		.welcome-header {
			padding: 1rem;
			flex-direction: column;
			align-items: center;
		}

		.registration-card {
			margin-top: 1rem;
			padding: 1rem;
		}
	}

	@media (max-width: 800px) {
		.video-tutorials-container {
			flex-direction: column;
			align-items: center;

			.video-tutorials-header {
				text-align: center;
			}
		}

		.action-cards-container {
			flex-direction: column;
			align-items: center;
			margin: 1rem;
		}
	}
}
