app-student-eligibility {
	.arrow {
		padding: 1rem;
		padding-left: 8rem;
		align-items: center;
	}

	.eligibility-container {
		padding: 8rem;
		background-color: #ffffff;
		min-height: 100%;
	}

	.bottom-card {
		padding: 1rem;
		border: 1px solid #e9ecef;
		border-radius: 1rem;
		background-color: #fafbfc;
		width: 28rem;
	}

	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	.new-eligibility-dialog .p-dialog .p-dialog-header,
	.partner-code-dialog .p-dialog .p-dialog-header {
		justify-content: flex-start;
	}

	.partner-code-dialog .p-dialog .p-dialog-header {
		padding-bottom: 0.8rem;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	@include media-breakpoint-down(lg) {
		.eligibility-container {
			padding: 4rem 1rem;
		}
	}
}
