app-partner-profile {
	.p-menu {
		padding: 0.5rem;
		width: 20rem;
		border-radius: 1rem;
	}
	.p-menuitem-link.p-menuitem-link-active > .p-menuitem-text {
		color: #2d98d4 !important;
	}

	.exam-apps-icon {
		background: url('../../../assets/icons/linear/exam-apps-icon.svg');
	}

	.login-icon {
		background: url('../../../assets/icons/linear/login-icon.svg');
	}

	.profile-icon {
		background: url('../../../assets/icons/linear/profile-icon.svg');
	}

	.billing-icon {
		background: url('../../../assets/icons/linear/billing-icon.svg');
	}

	.testing-sites-icon {
		background: url('../../../assets/icons/linear/testing-sites-icon.svg');
	}

	.p-menu .p-menuitem-link .p-menuitem-icon {
		margin-right: 1rem;
	}

	.exam-apps-icon,
	.login-icon,
	.billing-icon,
	.profile-icon,
	.testing-sites-icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 2.5rem;
		width: 2.5rem;
	}

	.router-item {
		flex-grow: 1;
	}

	.profile-padding {
		padding: 8rem 4rem;
	}

	@include media-breakpoint-down(xl) {
		.profile-padding {
			padding: 4rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.profile-padding {
			padding: 4rem 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.testing-site-btn {
			button {
				width: 100%;
			}
		}

		.billing-btns,
		.profile-info-btns {
			p-button {
				width: 100%;
			}

			button {
				width: 100%;
			}

			span {
				width: 100%;
				text-align: center;
			}
		}
	}
}
