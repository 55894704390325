app-course-note {
	figure.image > img {
		max-width: 100%;
		height: auto;
	}
	.note-data {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
}
