app-partner-students {
	.mobile-filters {
		display: none;
	}

	.invite-export-btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 2rem;
	}

	.export-btn {
		margin-top: 2rem;
	}

	.success-icon {
		color: #38cb89;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		font-size: 2rem;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 8px #e5f6ef;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	@media screen and (max-width: 960px) {
		.filters {
			display: none !important;
		}

		.invite-export-btns {
			margin-top: 0;
		}

		.export-btn {
			margin-top: 0;
		}

		.mobile-filters {
			display: block;
		}

		.mobile-dialog-filters {
			.p-dialog {
				.p-dialog-content {
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					padding: 1.5rem 1rem;
					background-color: $secondary;
				}
			}

			.p-checkbox .p-checkbox-box {
				&.p-highlight {
					background-color: white;
					border-color: $secondary;
				}

				.p-checkbox-icon {
					color: $secondary;
					font-weight: 600;
				}
			}

			.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: $secondary;
				background: white;
			}
		}
	}

	@media screen and (min-width: 960px) {
		.mobile-dialog-filters {
			display: none;
		}
	}

	@media screen and (max-width: 460px) {
		.invite-export-btns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 0.25rem;
			width: 100%;

			.p-button {
				width: 100%;
			}
		}

		.mobile-filters,
		.export-btn {
			width: 100%;
			margin-bottom: 0.25rem;

			.p-button {
				width: 100%;
			}
		}
	}
}
