app-codes {
	.codes-header {
		background: #ffffff;
		padding: 1.5rem 2rem;
		font-size: 1.5rem;
		font-weight: 600;
	}

	.codes-container {
		padding: 0 2rem;
		display: block;
		padding-bottom: 1rem;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	@include media-breakpoint-down(sm) {
		.codes-container {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}
}
