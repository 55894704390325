.p-component.p-tabmenu {
	margin-bottom: 1rem;

	.p-tabmenu-nav {
		background-color: white;
		padding: 0 1.5rem;
		border-width: 2px 0 2px 0;
		border-color: #f5f5f5;
		.p-menuitem-text {
			font-size: 13px;
		}
		.p-tabmenuitem {
			padding: 0 0.5rem;
			background: white;
			.p-menuitem-link {
				color: tint-color($sideBarText, 5%);
				padding: 1.25rem 0;
				border: solid #f5f5f5;
				border-width: 0 0 2px 0;
				border-color: transparent transparent #f5f5f5 transparent;
			}
		}
		.p-tabmenuitem.p-highlight .p-menuitem-link.p-menuitem-link-active {
			color: $secondary;
			font-weight: 500;
			position: relative;

			:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				background-color: $secondary;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}
		.p-tabmenuitem:hover,
		.p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
			background-color: white;
		}
		.p-tabmenuitem .p-menuitem-link,
		.p-tabmenuitem.p-highlight .p-menuitem-link {
			background-color: white;
			font-weight: 400;
		}
	}
}
