app-ce-course {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		align-items: center;
	}

	.ce-course-header {
		padding: 6rem 8rem;
		background: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
	}

	.ce-course-to-do-list {
		padding: 6rem 8rem;
		background: white;

		.to-do-list-container {
			max-width: 75%;

			.card {
				border-radius: 8px;
				background: #fafbfc;
				border: 1px solid #e9ecef;

				.circle {
					min-height: 24px;
					min-width: 24px;
					border-radius: 50%;
					border: 1px solid #2d98d4;
				}

				.circle-with-check {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 24px;
					min-width: 24px;
					border-radius: 50%;
					background: #2d98d4;
					color: white;

					i {
						font-size: 0.7rem;
						font-weight: 900;
					}
				}
			}
		}
	}

	.ce-course-pricing {
		padding: 6rem 8rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;
		gap: 2rem;

		.card {
			max-width: 350px;
			margin: 0 auto;

			.circle-with-check {
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 24px;
				min-width: 24px;
				border-radius: 50%;
				background: #e4f2f9;
				color: #2d98d4;

				i {
					font-size: 0.7rem;
					font-weight: 900;
				}
			}
		}
	}

	.progress-spinner-container {
		margin-top: 10rem;
	}

	@include media-breakpoint-down(xl) {
		.ce-course-header,
		.ce-course-to-do-list,
		.ce-course-pricing {
			padding: 3rem 2rem;
		}
	}

	@include media-breakpoint-down(md) {
		.ce-course-header,
		.ce-course-to-do-list,
		.ce-course-pricing {
			padding: 3rem 1rem;
		}

		.ce-course-header {
			flex-direction: column;
			gap: 1rem;
		}

		.ce-course-pricing {
			flex-direction: column;
			gap: 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.completed-ce-course-btns {
			p-button {
				width: 100%;

				.p-button {
					width: 100%;
				}
			}
		}
	}
}
