app-application-steps {
	.p-steps {
		.p-steps-item {
			&::before {
				margin-top: 0rem !important;
			}

			.p-menuitem-link {
				cursor: pointer;
				.p-steps-number {
					border-color: #2d98d4;
					color: #2d98d4;
				}

				.p-steps-title {
					display: none;
				}
			}

			&.p-highlight {
				.p-menuitem-link {
					box-shadow: 0 0 0 8px #e4f2f9;
					border-radius: 50%;

					&:not(.p-disabled):focus {
						box-shadow: 0 0 0 8px #e4f2f9;
					}

					.p-steps-number {
						background: #2d98d4;
						color: white;
					}
				}
			}
		}
	}

	.active-step-1 {
		.p-steps-item:nth-child(1) {
			.p-menuitem-link {
				.p-steps-number {
					background: #2d98d4;
					color: white;
				}
			}
		}
	}

	.active-step-2 {
		.p-steps-item:nth-child(1),
		.p-steps-item:nth-child(2) {
			.p-menuitem-link {
				.p-steps-number {
					background: #2d98d4;
					color: white;
				}
			}
		}
	}
}
