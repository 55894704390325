app-begin {
	.small-screen {
		display: none;
	}

	.image-checklist-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 80rem;
	}

	ul li {
		margin-bottom: 1rem;
	}

	.begin-image {
		max-width: 100%;
		height: auto;
	}

	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.title-container {
		margin-bottom: 8rem;
	}

	@media (max-width: 1525px) {
		.checklist-container {
			margin-left: 2rem;
		}
	}

	@media (max-width: 960px) {
		.image-checklist-container {
			flex-direction: column-reverse;
		}

		.small-screen {
			display: block;
			width: 100%;

			.p-button {
				width: 100%;
			}
		}

		.big-screen {
			display: none;
		}

		.title-container {
			margin-bottom: 3rem;
		}

		.begin-image-container {
			margin-top: 2rem;
		}
	}

	@media (max-width: 768px) {
		.checklist-container {
			margin-left: 0;
		}
		.image-checklist-container {
			align-items: center;
		}
	}

	@media (max-width: 525px) {
		.arrow {
			display: none;
		}
		.begin-container {
			padding-top: 4rem;
		}
	}
}
