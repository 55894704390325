app-permissions {
	.p-button.p-button-secondary.p-button-text.p-disabled {
		color: #e0e0e0;
	}

	.frozenCode {
		color: #e0e0e0;
	}

	.header-icon {
		font-size: 1.5rem;
	}

	//Overriding default checkbox color
	.p-checkbox .p-checkbox-box.p-highlight {
		background: #e4f2f9;
		border-color: #2d98d4;
	}

	//Overriding default checkbox color
	.p-checkbox .p-checkbox-box .p-checkbox-icon {
		color: #2d98d4;
		font-weight: 600;
	}

	// Overriding default bottom border of last row on table
	tbody tr:last-of-type td {
		border-bottom-style: hidden !important;
	}

	// Overriding default background color of table rows
	// Makes the bottom left/right corners of the table visible
	.p-datatable .p-datatable-tbody > tr {
		background: none;
	}

	.permissions-checkbox {
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: 960px) {
		.permissions-checkbox {
			justify-content: center;
		}
	}
}
