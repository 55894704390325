app-application-submitted {
	.green-check-icon {
		color: #38cb89;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: #e5f6ef;
		box-shadow: 0px 0px 0px 5px #e5f6ef;
	}
}
