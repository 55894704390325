// Use if needed to reach non-bootstrap global variables
// @import "../variables";

app-dashboard, ngb-offcanvas-panel {

    abp-routes > ul > li:first-child {
        margin-top: 0 !important;
    }
    
    background-color: #fafbfc;
    @include media-breakpoint-up(xl) {
        background-color: white;
    }

    .submenu-header > .dropdown-submenu > .dashboard-dropdown-item {
        padding-left: 1rem;
    }

    ul.navbar-nav>li.nav-item>a.nav-link.active {
        color: $secondary;
    }

    .dashboard-title {
        color: $primary;
    }
    
    .dashboard .dashboard-dropdown-item {
        color: $sideBarText;
        border: 0px;
        margin-left: 1.5rem;
        background-color: transparent;
    }

    .dashboard .dashboard-dropdown-item.active,
    .dashboard .dashboard-dropdown-item:hover {
        color: $secondary;
    }

    // Set dashboard item text color, remove default borders, spacing, highlighting on hover/active
    .dashboard .dashboard-item {
        color: $sideBarText;
        border: 0px;
        margin-left: 1.5rem;
        background-color: transparent;
    }
    .dashboard .dashboard-item.active,
    .dashboard .dashboard-item:hover {
        color: $secondary;
        background-color: transparent;
    }

    // Indicator bar on side nav items
    .dashboard-item::after {
        content: '';
        height: 100%;
        width: 4px;
        background: $secondary;
        border-radius: 4px 0 0 4px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
    }

    .dashboard-item.active::after,
    .dashboard-item:hover::after {
        opacity: 1;
    }
}

ngb-offcanvas-panel.offcanvas.offcanvas-start {
    width: 240px;
}