app-course-flashcard {
	height: 100%;
	display: flex;
	flex-direction: column;

	.flashcards {
		max-width: 30rem;
		margin-bottom: 2rem;
	}

	.learned {
		background-color: #ccf0c6;
	}

	.flashcard-btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.p-tabview-title {
		font-size: $font-size-xs;
	}

	.p-tabview .p-tabview-nav {
		background: transparent;
		font-size: 1rem;
		flex-wrap: wrap;
		border: solid #dee2e6;
		border-width: 0 0 2px 0;
	}

	.flashcards > .card-body > .flashcard-text {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
		margin-right: 0;
		justify-content: center;
	}

	.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
		background-color: transparent;
	}

	.p-tabview .p-tabview-nav {
		li.p-highlight .p-tabview-nav-link .p-tabview-title,
		li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link .p-tabview-title {
			font-weight: 700;
		}

		.p-tabview-title {
			font-size: 15px;
			font-weight: 400;
		}
	}

	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		background: transparent;
	}

	.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		background: transparent;
		color: $primary;
		border-color: $primary;
	}

	.p-tabview .p-tabview-nav .p-tabview-ink-bar {
		background: none;
	}

	.p-tabview .p-tabview-panels {
		background: transparent;
		padding-left: 0;
		padding-right: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	.p-element .p-tabview {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	@include media-breakpoint-down(xl) {
		.p-tabview .p-tabview-nav {
			justify-content: space-between;
			margin: 0;
		}

		.p-tabview .p-tabview-nav li {
			flex-grow: 1;
		}

		.p-tabview .p-tabview-nav li .p-tabview-nav-link {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	@media (min-width: 1199px) {
		.flashcard-col-size {
			flex: 0 0 auto;
			width: 50%;
		}
	}

	@media (min-width: 2100px) {
		.flashcard-col-size {
			flex: 0 0 auto;
			width: 33.33333333%;
		}
	}
}
