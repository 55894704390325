app-application-container {
	.arrow {
		padding: 1.5rem;
		padding-left: 8rem;
		display: flex;
		align-items: center;
	}

	.title-container {
		margin-bottom: 4rem;
	}

	.application-container {
		padding: 7rem;
		padding-top: 6rem;
	}

	@include media-breakpoint-down(xl) {
		.application-container {
			padding: 1rem;
			padding-top: 2rem;
		}
	}

	@media (max-width: 768px) {
		.arrow {
			display: none;
		}
	}

	@media (max-width: 600px) {
		.p-steps-title {
			display: none;
		}
	}
}
